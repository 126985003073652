import React, { useMemo, useState,useEffect, useRef } from 'react';
import { Button, Card,Col,FormControl, Row,InputGroup,Form,ListGroup  } from 'react-bootstrap';
import DataTable from '../../Components/Shared/DataTable';
import Reloj from '../../Components/Shared/Reloj';
import ModalBuscarProducto from '../../Components/Modals/ModalBuscarProducto';
import { RiDeleteBin6Line } from "react-icons/ri";
import ModalInventario from '../../Components/Modals/ModalInventario';
import ModalVerificarPrecio from '../../Components/Modals/ModalVerificarPrecio';
import axios from 'axios';
import Swal from 'sweetalert2';
import ModalRetiro from '../../Components/Modals/ModalRetiro';
import ModalCorte from '../../Components/Modals/ModalCorte';

const VentasPage = () => {
    const [productosVenta,setProductosVenta]= useState([]);
    const [clientesList,setClientesList]= useState([]);
    const user = JSON.parse(localStorage.getItem('user'));
    const token = localStorage.getItem('token');
    const [modalProductos, setModalProductos] = useState(false);
    const [modalInventario, setModalInventario] = useState(false);
    const [modalVerificador, setModalVerificador] = useState(false);
    const [modalRetiro, setModalRetiro] = useState(false);
    const [modalCorte, setModalCorte] = useState(false);
    const [cliente, setCliente] = useState(1);
    const [clienteNombre, setClienteNombre] = useState(1);
    const [formaPago, setFormaPago] = useState(1);
    const [folio, setFolio] = useState('');
    const [sku,setSku] = useState("");
    const [pago,setPago] = useState(0);
    const [cambio,setCambio] = useState(0);
    const inputRef = useRef(null);
    const [sucursal,setSucursal] = useState({});

    useEffect(() => {
        const fetchClientes = async () => {
            try {
                const response = await axios.get('https://api.meycisstore.com/clientes',{
                    headers:{
                        "Content-Type":"application/json",
                        "Authorization":`${token}`
                    },
                });
                await setClientesList(response.data);
                setCliente('1');

                const nombreCliente = response.data.find((item)=> item.id_cliente === parseInt('1'));
                setClienteNombre(`${nombreCliente.nombre} ${nombreCliente.apellidos}`);
            } catch (error) {
                console.error('Error al obtener clientes:', error);
            }
        };

        const fetchDataSucursal = async () =>{
            try {
                const responseSucursal = await axios.get(`https://api.meycisstore.com/sucursales/${user.id_sucursal}`,{
                headers:{
                    "Content-Type":"application/json",
                    "Authorization":`${token}`
                }});
                setSucursal(responseSucursal.data);
            } catch (error) {
                
            }
        }
        // Llamada a la función de carga de clientes al montar el componente
        fetchClientes();
        fetchDataSucursal();
    }, [token,user.id_sucursal]); 

    const columns = useMemo(
        () => [
            {
                Header: 'Producto',
                accessor: 'nombre_producto',
            },
            {
                Header: 'Talla',
                accessor: 'nombre_talla',
            },
            {
                Header: 'Cantidad',
                accessor: 'cantidad_compra',
                Cell: ({ row }) => {
                    const handleCantidadChange = (rowIndex, e) => {
                        const newCantidad = parseInt(e.target.value, 10) || 0;
    
                        if (newCantidad <= 0) {
                            Swal.fire({
                                title: 'Advertencia',
                                text: 'La cantidad ingresada es inválida',
                                icon: 'warning',
                            });
                            return;
                        }
    
                        if (newCantidad > productosVenta[rowIndex].cantidad) {
                            Swal.fire({
                                title: 'Advertencia',
                                text: 'La cantidad ingresada es mayor al inventario del producto',
                                icon: 'warning',
                            });
                            return;
                        }
    
                        const newProductos = [...productosVenta];
                        newProductos[rowIndex].cantidad_compra = newCantidad;
    
                        // Recalcular total y descuento
                        const totalSinDescuento = newProductos[rowIndex].precio_venta * newProductos[rowIndex].cantidad_compra;
                        const descuento = (totalSinDescuento * (newProductos[rowIndex].descuento || 0)) / 100;
                        const totalConDescuento = totalSinDescuento - descuento;
    
                        newProductos[rowIndex].total_descuento = descuento.toFixed(2);
                        newProductos[rowIndex].total = totalConDescuento.toFixed(2);
    
                        setProductosVenta(newProductos);
                    };
    
                    return (
                        <Form.Control
                            type="number"
                            value={row.original.cantidad_compra}
                            onChange={(e) => handleCantidadChange(row.index, e)}
                            min="0"
                            max={row.original.cantidad}
                        />
                    );
                },
            },
            {
                Header: 'Precio Unit.',
                accessor: 'precio_venta',
            },
            {
                Header: 'Descuento (%)',
                accessor: 'descuento',
                Cell: ({ row }) => {
                    const handleDescuentoChange = (rowIndex, e) => {
                        const newDescuento = parseInt(e.target.value, 10) || 0;
    
                        const newProductos = [...productosVenta];
                        newProductos[rowIndex].descuento = newDescuento;
    
                        // Recalcular total y descuento
                        const totalSinDescuento = newProductos[rowIndex].precio_venta * newProductos[rowIndex].cantidad_compra;
                        const descuento = (totalSinDescuento * newDescuento) / 100;
                        const totalConDescuento = totalSinDescuento - descuento;
    
                        newProductos[rowIndex].total_descuento = descuento.toFixed(2);
                        newProductos[rowIndex].total = totalConDescuento.toFixed(2);
                        newProductos[rowIndex].subtotal = totalConDescuento.toFixed(2);
    
                        setProductosVenta(newProductos);
                    };
    
                    return (
                        <Form.Select
                            value={row.original.descuento || 0}
                            onChange={(e) => handleDescuentoChange(row.index, e)}
                        >
                            <option value={0}>0%</option>
                            <option value={5}>5%</option>
                            <option value={10}>10%</option>
                            <option value={15}>15%</option>
                            <option value={20}>20%</option>
                            <option value={25}>25%</option>
                            <option value={30}>30%</option>
                            <option value={50}>50%</option>
                        </Form.Select>
                    );
                },
            },
            {
                Header: 'Descuento ($)',
                accessor: 'total_descuento',
                Cell: ({ row }) => {
                    return row.original.total_descuento || "0.00";
                },
            },
            {
                Header: 'Total',
                accessor: 'total',
                Cell: ({ row }) => {
                    return row.original.total || "0.00";
                },
            },
            {
                Header: 'Acciones',
                accessor: 'acciones',
                Cell: ({ row }) => {
                    const handleEliminarProducto = (rowIndex) => {
                        const newProductos = [...productosVenta];
                        newProductos.splice(rowIndex, 1);
                        setProductosVenta(newProductos);
                    };
                    return (
                        <div className="d-flex justify-content-center">
                            <Button variant="danger" onClick={() => handleEliminarProducto(row.index)}>
                                <RiDeleteBin6Line />
                            </Button>
                        </div>
                    );
                },
            },
        ],
        [productosVenta]
    );
    
    const totalGeneral = useMemo(() => {
        return productosVenta.reduce((total, producto) => {
            const totalProductoSinDescuento = producto.cantidad_compra * producto.precio_venta;
            const descuentoProducto = (totalProductoSinDescuento * (producto.descuento || 0)) / 100;
            const totalProductoConDescuento = totalProductoSinDescuento - descuentoProducto;
            return total + totalProductoConDescuento;
        }, 0);
    }, [productosVenta]);
    
    const subtotal = useMemo(() => {
        return productosVenta.reduce((total, producto) => {
            return total + producto.cantidad_compra * producto.precio_venta;
        }, 0);
    }, [productosVenta]);
    
    const descuento = useMemo(() => {
        return productosVenta.reduce((totalDescuento, producto) => {
            const totalProductoSinDescuento = producto.cantidad_compra * producto.precio_venta;
            const descuentoProducto = (totalProductoSinDescuento * (producto.descuento || 0)) / 100;
            return totalDescuento + descuentoProducto;
        }, 0);
    }, [productosVenta]);
    
    const utilidad = useMemo(() => {
        return productosVenta.reduce((total, producto) => {
            return total + ( producto.total - (producto.cantidad_compra * producto.precio_compra) );
        }, 0);
    }, [productosVenta]);
    
    const handleButtonClick = (valor) => {
        switch (valor) {
            case 'producto':
                setModalProductos(true);
                break;
            case 'inventario':
                setModalInventario(true);
                break;
            case 'verificar':
                setModalVerificador(true);
                break;
            case 'retiro':
                setModalRetiro(true);
                break;
            case 'corte':
                setModalCorte(true);
                break;
            default:
                break;
        }
    };
    

    const onHide = ()=>{
        setModalProductos(false);
        setModalInventario(false);
        setModalVerificador(false);
        setModalRetiro(false);
        setModalCorte(false);
    };

    const agregarArticulo = (producto) => {
        // Verificar si el producto ya está en la lista
        const productoExistente = productosVenta.find((p) => p.id_producto === producto.id_producto && p.id_talla === producto.id_talla );

        if (productoExistente) {
            if(productoExistente.cantidad < productoExistente.cantidad_compra+1){
                Swal.fire({
                    icon:'info',
                    title:'Informacion',
                    text:'No se pueden agregar mas productos de los que tiene en existencia'
                });
                return;
            }
            // Si el producto ya existe, incrementar la cantidad en 1
            const nuevosProductos = productosVenta.map((p) =>
            p.id_producto === productoExistente.id_producto && p.id_talla === productoExistente.id_talla  ? { ...p, cantidad_compra: p.cantidad_compra + 1, total:(p.precio_venta * (p.cantidad_compra + 1)).toFixed(2), descuento:0, total_descuento:0,subtotal:(p.precio_venta * (p.cantidad_compra + 1)).toFixed(2) } : p
            );
            setProductosVenta(nuevosProductos);
            
        } else {
            // Si el producto no existe, agregarlo a la lista con cantidad 1
            setProductosVenta((prevProductos) => [...prevProductos, { ...producto, cantidad_compra: 1,descuento:0,total_descuento:0.0,total:producto.precio_venta.toFixed(2),subtotal:producto.precio_venta.toFixed(2) }]);
        }
        setCambio(0);
    };
    
    const handleChange = (e)=>{
        const { value } = e.target;
        setSku(value);
        if( value.length === 9 )
        {
            console.log(value);
        }
    }

    const handleClienteChange = (selectedCliente) => {
        setCliente(selectedCliente);
        const nombreCliente = clientesList.find((item)=> item.id_cliente === parseInt(selectedCliente));
        setClienteNombre(`${nombreCliente.nombre} ${nombreCliente.apellidos}`);
    };

    const handleFormaPagoChange = (selectedFormaPago) => {
        setFormaPago(selectedFormaPago);
    };

    const handleChangePago = (e)=>{
        const { value } = e.target;
        setPago(value);
    }

    const handleSubmit = (e) =>{
        e.preventDefault();
        if (sku.length === 9) {
            axios.get('https://api.meycisstore.com/productos/searchsku',{
                headers:{
                    "Content-Type":"application/json",
                    "Authorization":`${token}`
                },
                params:{
                    id_sucursal:user.id_sucursal,
                    sku:sku
                }
            }).then(results=>{
                console.log(results);
                if(results.status === 200)
                {
                    agregarArticulo(results.data[0]);
                }
            }).catch((error)=>{
                console.log(error);
                Swal.fire({
                    title:'Advertencia',
                    text:'No se encontro ningun producto',
                    icon:'info'
                })
            }).finally(()=>{
                setSku('');
                inputRef.current.focus();
            })
        }
    }
    
    const resetVenta = () => {
        setCliente(1);
        setFormaPago(1);
        setPago(0);
        setFolio('');
        setProductosVenta([]);
        inputRef.current.focus();
    }

    const handleSubmitPago = (e)=>{
        e.preventDefault();

        if (totalGeneral === 0) {
            Swal.fire({
                title:'Advertencia',
                text:'No hay elementos para la venta',
                icon:'warning'
            });
            return;
        }
        if (formaPago === "2" && isNaN(parseInt(folio)) ) {
            Swal.fire({
                title:'Advertencia',
                text:'Debe ingresar el folio del pago con tarjeta',
                icon:'warning'
            });
            return;
        }

        if(pago >= totalGeneral)
        {
            const arrayModificado = productosVenta.map((objeto) => ({
                ...objeto,
                cantidad: objeto.cantidad.toFixed(2).toString(),
                precio_venta: objeto.precio_venta.toFixed(2).toString(),
            }));
            const ticket = {
                sucursal:sucursal.nombre,
                direccion:sucursal.direccion,
                cliente:clienteNombre,
                usuario:`${user.nombre} ${user.apellido}`,
                productos:arrayModificado,
                subtotal:subtotal.toFixed(2),
                descuento:descuento.toFixed(2),
                total:totalGeneral.toFixed(2)
            };
            const datosVenta ={
                id_cliente:cliente, 
                id_user: user.id_user, 
                id_sucursal:user.id_sucursal, 
                monto_total:totalGeneral, 
                total_descuento:descuento, 
                ticket_info:ticket,
                total_utilidad:utilidad, 
                detalles:productosVenta,
                formaPago:formaPago,
                folio:folio
            };
            axios.post("https://api.meycisstore.com/venta",datosVenta,{
                headers:{
                    "Content-Type":'application/json',
                    Authorization:token
                }
            }).then((response)=>{
                setCambio(pago-totalGeneral);
                Swal.fire({
                    title:'Confirmacion',
                    text:'Venta completada con exito!',
                    icon:'success'
                }).then((result)=>{
                    axios.post('http://localhost/tickets/ticket.php',ticket)
                    .then((response)=>{console.log(response.data)})
                    .catch((error)=>{ console.log(error); })
                    resetVenta();
                });
            }).catch((error)=>{
                Swal.fire({
                    title:'Advertencia',
                    text:`Ocurrio un error durante la venta:${error.response.data.message}`,
                    icon:'warning'
                })
            })
        }else
        {
            Swal.fire({
                title:'Advertencia',
                text:'El pago ingresado es menor al total de la venta',
                icon:'warning'
            });
        }
    }

    return(
        <Card className='h-100'>
            <Card.Header className='d-flex justify-content-between'>
                <Card.Title>{user.nombre} {user.apellido} </Card.Title>
                <Reloj></Reloj>
            </Card.Header>
            <Card.Body>
                <Row style={{ height: "70vh" }}>
                    <Col sm={12} md={10}>
                        <Col sm={12} className='mb-3'>
                            <div className="tools d-flex align-items-center p-3 border rounded">
                                <Col sm={12} md={8}>
                                    <Button variant="secondary" size="lg" onClick={() => handleButtonClick('retiro')} className="me-2">
                                        Crear Retiro
                                    </Button>
                                    <Button variant="secondary" size="lg" onClick={() => handleButtonClick('corte')} className="me-2">
                                        Realizar Corte
                                    </Button>
                                    <Button variant="secondary" size="lg" onClick={() => handleButtonClick('producto')} className="me-2">
                                        Buscar Producto
                                    </Button>
                                    <Button variant="secondary" size="lg" onClick={() => handleButtonClick('inventario')} className="me-2">
                                        Inventario
                                    </Button>
                                    <Button variant="secondary" size="lg" onClick={() => handleButtonClick('verificar')} className="me-2">
                                        Verificar Precio
                                    </Button>
                                </Col>
                                <Col sm={12} md={4}>
                                    <Form onSubmit={handleSubmit}>
                                        <InputGroup className='d-flex align-items-center justify-content-center'>
                                            <InputGroup.Text>SKU</InputGroup.Text>
                                            <FormControl placeholder='Ingresar SKU' type="text" ref={inputRef} onChange={handleChange} value={sku} />
                                        </InputGroup>
                                    </Form>
                                </Col>
                            </div>
                        </Col>
                        <Col sm={12} className=' h-100 border rounded'>
                            <DataTable data={productosVenta} columns={columns} searchParam={true} showPagination={true} />
                        </Col>
                    </Col>
                    <Col sm={12} md={2}>
                        <Card className='mb-3'>
                            <Card.Header className='d-flex justify-content-between'>
                                <Card.Title> Resumen  de la Venta </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Col sm={12}>
                                    <ListGroup variant="flush" className='text-end mb-3'>
                                        <ListGroup.Item>Productos: {productosVenta.length}</ListGroup.Item>
                                        <ListGroup.Item>Subtotal: ${subtotal.toFixed(2)}</ListGroup.Item>
                                        <ListGroup.Item>Descuento: ${descuento.toFixed(2)}</ListGroup.Item>
                                    </ListGroup>
                                    <p className='h1 text-end'>$ {totalGeneral.toFixed(2)} </p>
                                </Col>
                            </Card.Body>
                        </Card>
                        <Card>
                            <Card.Header className='d-flex justify-content-between'>
                                <Card.Title> Informacion del Pago </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Col sm={12}>
                                    <Form.Group controlId="cliente" className='mb-3'>
                                        <Form.Label>Cliente</Form.Label>
                                        <Form.Select
                                        aria-label="Seleccionar Cliente"
                                        value={cliente ? cliente.id_cliente : ''}
                                        onChange={(e) => handleClienteChange(e.target.value)}
                                        >
                                        {clientesList.map((cliente) => (
                                            <option key={cliente.id_cliente} value={cliente.id_cliente}>
                                            {cliente.nombre} {cliente.apellidos}
                                            </option>
                                        ))}
                                        </Form.Select>
                                    </Form.Group>
                                    <Form.Group controlId="formaPago" className='mb-3'>
                                        <Form.Label>Forma de Pago</Form.Label>
                                        <Form.Select
                                        aria-label="Seleccionar Forma de Pago"
                                        value={formaPago ? formaPago : ''}
                                        onChange={(e) => handleFormaPagoChange(e.target.value)}
                                        >
                                        <option value="">Seleccionar forma de pago</option>
                                        <option value="1">Efectivo</option>
                                        <option value="2">Tarjeta</option>
                                        </Form.Select>
                                    </Form.Group>
                                    {
                                        formaPago === '2' ?
                                        (
                                        <Col sm={12}>
                                            <InputGroup className='d-flex align-items-center justify-content-center mb-3'>
                                            <InputGroup.Text># Folio</InputGroup.Text>
                                            <FormControl
                                                placeholder='Número de folio'
                                                type="text"
                                                onChange={(e) => setFolio(e.target.value)}
                                                value={folio}
                                            />
                                            </InputGroup>
                                        </Col>
                                        ) : null
                                    }
                                    <Form onSubmit={handleSubmitPago}>
                                        <InputGroup className='d-flex align-items-center justify-content-center mb-3'>
                                            <InputGroup.Text>Pago</InputGroup.Text>
                                            <FormControl placeholder='Ingresar la cantidad' type="number" onChange={handleChangePago} value={pago} step={.5}/>
                                        </InputGroup>
                                    </Form>
                                    <p className='h1 text-end'> <span className='h6'>Cambio</span> $ {cambio.toFixed(2)} </p>
                                </Col>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <ModalBuscarProducto show={modalProductos} onHide={onHide} onAddProduct={agregarArticulo} ></ModalBuscarProducto>
                <ModalInventario show={modalInventario} onHide={onHide} ></ModalInventario>
                <ModalVerificarPrecio show={modalVerificador} onHide={onHide}></ModalVerificarPrecio>
                <ModalRetiro show={modalRetiro} onHide={onHide} ></ModalRetiro>
                <ModalCorte show={modalCorte} onHide={onHide} ></ModalCorte>
            </Card.Body>
        </Card>
    )
}

export default VentasPage;